<template>
	<div id="app">
		<router-view></router-view>
		<p class="cr">© John Dews-Flick {{year}}</p>
	</div>
</template>

<script>

export default {
	data(){
		return {
			year: (new Date().getFullYear())
		}
	}
}
</script>

