<template>
  <div class="card">
      <div class="card-body"><h1>Build Process:</h1>
      
<p>


	I custom designed the snowflake starting in early November 2021 with motivation from a different successful led PCB project. Using the company <a href="https://jlcpcb.com/">JLCPCB</a> I ordered the snowflake PCBs with the components already soldered on the front. I created an animation program that allowed me to visually create the animations on the snowflake. The snowflake itself is based on an ESP8266 chip and is programmed in C. The components on the back were hand soldered, 3D printed, assembled, and glued by me. The batteries used are of various quality as they were scavenged from old power banks, controllers, and toys.

</p>




<div class="row g-0 images">
   <div class="col-4">
      <img src="./static/diagram.png" alt="">
      <p>PCB design schematic</p>
  </div>
   <div class="col-4">
      <img src="./static/pcb_layout.png" alt="">
      <p>PCB design and layout.</p>
  </div>
   <div class="col-4">
      <img src="./static/animator.png" alt="">
      <p>Custom pattern animation software</p>
  </div>
  <div class="col-4">
      <img src="./static/IMG_1076.jpg" alt="">
      <p>3D printing the largest sized battery housing.</p>
  </div>
 <div class="col-4">
      <img src="./static/IMG_1078.jpg" alt="">
      <p>Recycled lithium 3.7v cells.</p>
  </div>
 <div class="col-4">
      <img src="./static/IMG_1081.jpg" alt="">
      <p>Battery and charge controller fitted in the case.</p>
  </div>


  <div class="col-4">
      <img src="./static/IMG_3185.jpg" alt="">
      <p>Programming and debugging jig.</p>
  </div>

  <div class="col-4">
    <img src="./static/IMG_1087.jpg" alt="">
    <p>Close up of the pogo pins and contact points.</p>
  </div>

  <div class="col-4">
    <img src="./static/board.png" alt="">
    <p>Finished snowflake in a stand.</p>
  </div>






</div>      
      
      
      
      
      
      
      
      
      
      
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

.images{
    img{
    max-width: 100%;
    padding: 5px;
  }

  p{
    text-align: center;
  }
}

</style>

