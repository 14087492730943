import 'regenerator-runtime/runtime'
import Vue from 'vue/dist/vue.js';
import VueRouter from 'vue-router'
import Home from './Home.vue'
import App from './App.vue'
//import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import VueTypedJs from 'vue-typed-js/src/components/VueTypedJs.vue'

import "bootstrap/dist/css/bootstrap.css";
import 'material-icons/iconfont/material-icons.css';
import "@fontsource/roboto"; // Defaults to weight 400.\
import "@fontsource/anton"; 
import "./main.scss"; // Defaults to weight 400.


let snowstorm = require('./external/snowstorm');



Vue.component('vue-typed-js', VueTypedJs)


Vue.use(VueRouter);
Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    { path: '/', component: Home }
  ]
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  template: '<App/>',
  components: { App },
  router
});