<template>
  <div class="container p-2" style="max-width: 1200px">
    <Intro></Intro>
    <Build></Build>

    <div class="card pb-5">
      <div class="card-body"><h1>Included Instructions:</h1></div>
      <div class="pdf-view">
        <vue-pdf-embed source="./static/directions.pdf" />
      </div>
    </div>
  </div>
</template>

<script>
import Build from "./Build.vue"
import Intro from "./Intro.vue";



export default {
  data() {
    return {
    };
  },
  mounted(){

  
  
  },
  components: {  vuePdfEmbed: () => import('vue-pdf-embed/dist/vue2-pdf-embed'), Build, Intro }
};
</script>

<style lang="scss">
.pdf-view {
  width: 100%;
  min-height: 500px;
  max-width: 10in;
  margin: auto;
  max-height: 1000px;
  height: 80vh;
  overflow-y: auto;
  border: solid 1px grey;
}

.type-holder {
  height: 60px;
  width: 100%;
}

.card-body {
}

.card {
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 10px;
}
</style>