<template>
   <div class="card">
      <div class="card-body">
        <div class="type-holder">
          <h1>
            <vue-typed-js
              :strings="texts"
              :showCursor="true"
              :loop="true"
              :type-speed="75"
              :back-delay="1250"

              ><span class="typing"></span
            ></vue-typed-js>
          </h1>
        </div>

        <p>
Season's greetings and Merry Christmas. 2021 has been a big year for me, graduating with my undergraduate degree and continuing with my masters. So many people, including you, played an integral role in supporting me in my success. I’m grateful for your presence in my life. Here is a custom snowflake light-up ornament I designed, manufactured, and assembled. Enjoy! I hope you have a wonderful winter holiday. 
        </p>
      </div>
    </div>

</template>

<script>
let texts = require("./texts.json");

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default {
  data() {
    return {
      texts: shuffle(texts),
    };
  }
};
</script>

<style>

</style>